import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { color, font, ease, device } from "../../../layout/global"
import { CountryDropdown } from "react-country-region-selector" // eslint-disable-line

import SubmitButton from "../../SubmitButton"
import TermsCheckbox from "../../TermsCheckbox"
import { ConsentGtag } from "../../../utils/consentGtag"

const axios = require("axios")

const Form = ({ id, lang, info, infoConfig, context, strings, oven }) => {

  const [showLoader, setShowLoader] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorToShow, setErrorToShow] = useState([]);
  const [focused, setFocused] = useState(false)
  const [country, setCountry] = useState("")

  const validityString = useRef(),
    validityStringEmail = useRef(),
    inputField1 = useRef(),
    inputField2 = useRef(),
    inputField3 = useRef(),
    inputField4 = useRef(),
    inputField5 = useRef()

  const selectCountry = val => {
    setCountry(val)
  }

  var invalidFormatString = ''
  var missingFieldString = ''
  var regexCode = ''
  switch (lang) {
    case "pt":
    default:
      invalidFormatString = ' - Formato inválido'
      missingFieldString = ' - Campo obrigatório'
      regexCode = new RegExp("[0-9]{4}([-][0-9]{3})?", "g")
      break
    case "br":
      invalidFormatString = ' - Formato inválido'
      missingFieldString = ' - Campo obrigatório'
      regexCode = new RegExp("[0-9]{5}[-]?[0-9]{3}", "g")
      break
    case "en":
      invalidFormatString = ' - Invalid format'
      missingFieldString = ' - Mandatory field'
      regexCode = new RegExp("[0-9][A-Z]?[ ]?[ABD-HJLN-UW-Z]{2}", "g")
      break
    case "es":
    case "mx":
      invalidFormatString = ' - Formato inválido'
      missingFieldString = ' - Campo obligatorio'
      regexCode = new RegExp("[0-9]{5}", "g")
      break
    case "fr":
      invalidFormatString = ' - format invalide'
      missingFieldString = ' - Champ obligatoire'
      regexCode = new RegExp('[0-9]{2}[ ]?[0-9]{3}', "g")
      break
  }


  const labelName = [
    context.label1,
    context.label2,
    context.label3,
    context.label4,
    context.label5,
    context.label6,
    context.label7,
    context.label8,
  ]
  const labelValue = [
    context.option1,
    context.option2,
    context.option3,
    context.option4,
    context.option5,
    context.option6,
    context.option7,
    context.option8,
  ]

  function checkValues() {
    //nome
    if (inputField1.current.validity.valueMissing) errorToShow.push(info.field1 + missingFieldString)

    //email
    if (inputField2.current.validity.typeMismatch) {
      errorToShow.push(info.field2 + invalidFormatString)
    } else if (inputField2.current.validity.valueMissing)
      errorToShow.push(info.field2 + missingFieldString)

    return errorToShow.length
  }

  function doSubmit(e) {
    e.preventDefault()
    setShowLoader(true)
    ConsentGtag()

    var model = context.option1

    var check = checkValues()
    if (check <= 0) {
      var formData = new FormData()
      formData.append("name", document.querySelector("#fg-name").value)
      formData.append("email", document.querySelector("#fg-email").value)
      formData.append("tel", document.querySelector("#fg-tel").value)
      formData.append("country", country)
      formData.append("postalcode", document.querySelector("#fg-postal-code").value)
      formData.append("option", 5)
      formData.append("message", document.querySelector("#fg-message").value)
      document.querySelector("#terms-01").checked && formData.append("terms01", document.querySelector("#terms-01").value)
      document.querySelector("#terms-02").checked && formData.append("terms02", document.querySelector("#terms-02").value)
      formData.append("oven", context.oven)
      formData.append("title", context.title)
      formData.append("subtitle", context.subtitle)
      formData.append("lang", lang)

      // Strings
      formData.append("stringConfig", strings.config)
      formData.append("stringBy", strings.by)
      formData.append("stringClientData", strings.clientData)
      formData.append("stringConfigData", strings.configData)
      formData.append("stringImage", strings.image)
      formData.append("stringMessage", strings.message)
      formData.append("stringName", strings.name)
      formData.append("stringEmail", strings.email)
      formData.append("stringTel", strings.tel)
      formData.append("stringCountry", strings.country)
      formData.append("stringPostal", strings.postal)

      formData.append("labelValue", JSON.stringify(labelValue))
      formData.append("labelName", JSON.stringify(labelName))
      formData.append(
        "image",
        `/configurador/${context.oven}/jpg/0${context.value1}_0${context.value2}_0${context.value3}_0${context.value4}_0${context.value5}_01_0${context.value7}_0${context.value8}.jpg`
      )

      axios.post("https://ramalhos.com/form-config.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
        .then(res => {
          var aux = res.data.split("<br>")
          var len = aux.length
          if (aux[len - 1].includes("success")) {
            setShowLoader(false)
            onSuccess(model)
          } else if (res.data.includes('SMTP Error: The following recipients failed')) {
            setShowLoader(false)
            if (!errorToShow.includes(info.field2 + missingFieldString)) {
              errorToShow.push(info.field2 + missingFieldString)
            }
            setSuccessful(false)
            setHasError(true)
            onError()
          } else {
            setShowLoader(false)
            onError()
          }
        })
    }
    else {
      setShowLoader(false)
      setHasError(true)
      onError()
    }
  }

  useEffect(() => {
    if (!showLoader) {
      if (document.getElementById(`${id}`)) {
        document.getElementById(`${id}`).style.opacity = "1"
      }
      switch (lang) {
        case "pt":
        case "br":
          validityString.current = `Campo obrigatório.`
          validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
          break
        case "en":
          validityString.current = `Mandatory field.`
          validityStringEmail.current = `The e-mail must be in the following format: xxx@yyy.zzz`
          break
        case "es":
        case "mx":
          validityString.current = `Campo obligatorio.`
          validityStringEmail.current = `El correo electrónico debe tener el siguiente formato: xxx@yyy.zzz`
          break
        case "fr":
          validityString.current = `Champ obligatoire.`
          validityStringEmail.current = `L'e-mail doit être au format suivant : xxx@yyy.zzz`
          break
        default:
          validityString.current = `Campo obrigatório.`
          validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
          break
      }
      inputField1.current.setCustomValidity(validityString.current)
      inputField2.current.setCustomValidity(validityString.current)
      inputField3.current.setCustomValidity(validityString.current)
      inputField5.current.setCustomValidity(validityString.current)
    }
  }, [lang, id])

  useEffect(() => {
    if (focused && !showLoader) {
      !inputField1.current.validity.valueMissing
        ? inputField1.current.setCustomValidity("")
        : inputField1.current.setCustomValidity(validityString.current)

      inputField2.current.validity.typeMismatch
        ? inputField2.current.setCustomValidity(validityStringEmail.current)
        : inputField2.current.validity.valueMissing
          ? inputField2.current.setCustomValidity(validityString.current)
          : inputField2.current.setCustomValidity("")

      !inputField3.current.validity.valueMissing
        ? inputField3.current.setCustomValidity("")
        : inputField3.current.setCustomValidity(validityString.current)
      !inputField5.current.validity.valueMissing
        ? inputField5.current.setCustomValidity("")
        : inputField5.current.setCustomValidity(validityString.current)
      setFocused(false)
    }
  }, [focused])

  const doFocus = () => {
    setFocused(true)
  }
  const onSuccess = (model) => {
    document.getElementById("contact-form").style.opacity = "0"
    setTimeout(function () {
      setSuccessful(true)
      setTimeout(function () {
        document.getElementById("successful").style.opacity = "1"
        setTimeout(function () {
          document.getElementById("successful").style.opacity = "0"
          setTimeout(function () {
            setSuccessful(false)
            setTimeout(function () {
              document.getElementById("contact-form").style.opacity = "1"
              context.update(null, null, null)
            }, 150)
          }, 300)
        }, 3500)
      }, 150)
    }, 300)

    if (typeof window !== "undefined") {
      window.gtag("event", "form_configuradores", {
        form: "Formulário dos Configuradores",
        gama: context.oven ? context.oven : ' ',
        model: model ? model : ' '
      })
      window.gtag('event', 'conversion', { 'send_to': 'AW-11288001937/03dSCOSm1OMYEJH7xIYq' });
    }

  }
  const onError = () => {
    document.getElementById("contact-form").style.opacity = "0"
    setTimeout(function () {
      setHasError(true)
      setTimeout(function () {
        document.getElementById("error").style.opacity = "1"
        setTimeout(function () {
          document.getElementById("error").style.opacity = "0"
          setTimeout(function () {
            setHasError(false)
            setTimeout(function () {
              document.getElementById("contact-form").style.opacity = "1"
            }, 150)
          }, 3000)
        }, 3500)
      }, 150)
    }, 300)
  }

  return (
    <Wrapper>
      <div className="container">
        {showLoader ?
          <LoaderStyle>
            <div className="spinner-container">
              <div className="loading-spinner">
              </div>
            </div>
          </LoaderStyle>
          : hasError ? (
            <div id="error">
              <h2>{errorToShow.join(', ')}</h2>
              <h3>{info.error1}</h3>
              <p>{info.error2}</p>
            </div>
          ) : !successful ? (
            <form id={id} method="POST" autoComplete="off" onSubmit={doSubmit}>
              <div className="input-wrapper" id="fgw-info">
                <h2>{info.text3}</h2>
              </div>
              <div className="input-wrapper" id="fgw-name">
                <input
                  ref={inputField1}
                  onChange={doFocus}
                  className="fg-input"
                  id="fg-name"
                  name="name"
                  type="text"
                  placeholder={info.field1}
                  required
                ></input>
                <label htmlFor="fg-name" className="label">
                  {info.field1} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fgw-email">
                <input
                  ref={inputField2}
                  onChange={doFocus}
                  className="fg-input"
                  id="fg-email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  required
                ></input>
                <label htmlFor="fg-email" className="label">
                  {info.field2} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fgw-tel">
                <input
                  ref={inputField3}
                  onChange={doFocus}
                  className="fg-input"
                  id="fg-tel"
                  name="tel"
                  type="text"
                  placeholder="Tel"
                  pattern="[0-9]*"
                  required
                ></input>
                <label htmlFor="fg-tel" className="label">
                  {info.field3} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fgw-country">
                <CountryDropdown
                  id="fg-country"
                  name="country"
                  defaultOptionLabel=""
                  required
                  value={country}
                  onChange={val => selectCountry(val)}
                  ref={inputField4}
                />
                <label htmlFor="fg-country" className="label">
                  {info.field4} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div className="input-wrapper" id="fgw-postal-code">
                <input
                  ref={inputField5}
                  onChange={doFocus}
                  className="fg-input"
                  id="fg-postal-code"
                  name="postal-code"
                  type="text"
                  placeholder="Código Postal"
                  required
                ></input>
                <label htmlFor="fg-postal-code" className="label">
                  {info.field5} <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div id="fgw-secondary">
                <div className="form-secondary">
                  <div className="input-wrapper-message">
                    <textarea className="fg-message" id="fg-message" placeholder={info.field6}></textarea>
                    <label htmlFor="fg-message" className="category-label message-label">
                      {info.field6}
                    </label>
                    <TermsCheckbox
                      hasTerms
                      hasShare
                      text1={info.button1}
                      text2={info.button2}
                      text3={info.button3}
                      language={lang}
                    />
                  </div>
                  <div className="submit-wrapper">
                    <SubmitButton text={info.submit} />
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div id="successful">
              <span></span>
              <h3>{info.confirmation1}</h3>
              <p>{info.confirmation2}</p>
            </div>
          )}
      </div>
    </Wrapper>
  )
}


const LoaderStyle = styled.div`
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid rgba(225,6,0,1); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}`

const Wrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
  z-index: 1;
  position: relative;

  @media ${device.mobileP} {
    padding: 5rem 0;
  }

  @media ${device.tabletP} {
    padding: 15rem 0;
  }

  #successful {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto 1fr;
    justify-content: center;
    span {
      display: block;
      width: 7px;
      height: 12px;
      border: solid ${color.red};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      justify-self: center;
      margin-bottom: 25px;
    }
    h2 {
      ${font.financierBoldN};
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    h3 {
      ${font.financierBoldN};
      font-size: 2.5rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${font.robotoMedium};
      font-size: 1.5rem;
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  #error {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto auto;
    justify-content: center;
    h2 {
      ${font.financierBoldN};
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    h3 {
      ${font.financierBoldN};
      font-size: 1.4rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${font.robotoMedium};
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  .container {
    @media ${device.tabletP} {
      width: 90%;
      max-width: 900px;
    }
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  form {
    display: grid;
    width: 80%;
    opacity: 1;
    transition: all 0.3s ${ease.out};

    @media ${device.mobileP} {
      grid-template-areas:
        "info"
        "name"
        "email"
        "tel"
        "country"
        "postal"
        "secondary";
    }

    @media ${device.tabletP} {
      grid-template-areas:
        "info info info"
        "name name name"
        "email email tel"
        "country country postal"
        "secondary secondary secondary";
    }
  }

  #fgw-info {
    grid-area: info;
  }
  #fgw-name {
    grid-area: name;
  }
  #fgw-email {
    grid-area: email;
  }
  #fgw-tel {
    grid-area: tel;
  }
  #fgw-country {
    grid-area: country;
  }
  #fgw-postal-code {
    grid-area: postal;
  }
  #fgw-secondary {
    grid-area: secondary;
  }

  .input-wrapper {
    position: relative;
    margin: 10px;

    @media (max-width: 768px) {
      margin: 2vw 10px 0 10px;
    }
  }

  .input-wrapper h2 {
    text-align: center;
    ${font.financierRegularM};

    font-size: 2.4rem;
    color: ${color.greyDark};
  }

  .fg-input,
  #fg-country {
    width: 100%;
    border: 0;
    outline: 0;
    min-height: 44px;
    background-color: ${color.greyOpacity};
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 12px 6px;
    box-shadow: none;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fg-input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .label {
        transform: translateY(-3px) scale(0.75);
        opacity: 0.75;
      }
    }
  }
  #fg-country:valid ~ .label {
    transform: translateY(-3px) scale(0.75);
    opacity: 0.75;
  }

  #fg-country {
    -webkit-appearance: none;
    border-radius: 0px;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 95% 50%;
    background-color: ${color.greyOpacity};
  }

  #fgw-email .fg-input {
    text-transform: unset;
  }

  .label {
    position: absolute;
    left: 12px;
    top: 6px;
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .category-label {
    ${font.robotoMedium};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${color.grey};
    font-size: 1.4rem;
    margin-left: 16px;
    transition: all 0.15s ${ease.out};
    user-select: none;

    @media ${device.tabletP} {
      position: absolute;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .input-wrapper-message {
    position: relative;

    @media ${device.mobileP} {
      margin: 25px 10px;
    }

    @media ${device.tabletP} {
      margin: 25px 0 0;
    }
  }

  .fg-message {
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    padding: 15px 0px;
    outline: 0;
    width: 100%;
    position: relative;
    ${font.robotoMedium};
    letter-spacing: normal;
    color: ${color.greyDark};
    font-size: 1.4rem;
    min-height: 10rem;
    resize: vertical;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fg-message ~ .category-label {
    left: 0;
    margin-left: 0;
    transform: translateY(1.5rem);
    transform-origin: left;
    color: ${color.greyDark};
  }

  .fg-message {
    &:focus,
    &:not(:placeholder-shown) {
      & + .category-label {
        transform: translateY(-0.5rem) scale(0.75);
        margin-left: 1px;
        opacity: 0.75;
      }
    }
  }

  .submit-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .message-label {
    @media ${device.mobileP} {
      position: absolute;
    }
  }
`

export default Form
